import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Doughnut } from "react-chartjs-2";
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { Container, Card, CardBody, CardHeader, CardFooter, Col, Form, Row, Label, Input } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import excelImg from '../../assets/images/datatable/excel.png';
import dollarSales from '../../assets/images/datatable/dollar-sales.png';
import dollarStorySales from '../../assets/images/datatable/dollar-story-sales.png';
import dollarAddOnSales from '../../assets/images/datatable/dollar-add-on-sales.png';
import dollarRenewalOnSales from '../../assets/images/datatable/dollar-renewal-sales.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { reportOperations } from '../../state/ducks/report';
import Pagination from '../Pagination';
import '../../assets/scss/pagination.css';
import { formatDateAndTime } from '../../utils/helper';

class SalesDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRange: 'today',
      startDate: '',
      endDate: '',
      totalSales: '',
      storySales: '',
      addOnSales: '',
      renewalSales: '',
      revenueGraphData: '',
      salesTableData: [],
      defaultSearchData: [],
      searchData: '',
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: 'asc'
    };
  }

  async componentDidMount() {
    try {
      // get today data
      const response = await this.props.salesDashboard({ filterType: "days", filterDate: "today" });
      this.setState({ totalSales: response.payload.total_sales });
      this.setState({ storySales: response.payload.story_sales });
      this.setState({ addOnSales: response.payload.addOn_sales });
      this.setState({ renewalSales: response.payload.renewal_sales });
      this.setState({ revenueGraphData: response.payload.revenue });
      this.setState({ salesTableData: response.payload.sales_data, defaultSearchData: response.payload.sales_data });
    } catch (err) {
      console.log(err);
    }
  }

  handleRangeChange = async (range) => {
    this.setState({ selectedRange: range, startDate: '', endDate: '' });
    if (['today', '7d', '30d'].includes(range)) {
      const response = await this.props.salesDashboard({ filterType: "days", filterDate: range });
      this.setState({
        totalSales: response.payload.total_sales,
        storySales: response.payload.story_sales,
        addOnSales: response.payload.addOn_sales,
        renewalSales: response.payload.renewal_sales,
        revenueGraphData: response.payload.revenue,
        salesTableData: response.payload.sales_data,
        defaultSearchData: response.payload.sales_data
      });
    } else if (['3m', '12m'].includes(range)) {
      const response = await this.props.salesDashboard({ filterType: "months", filterDate: range });
      this.setState({
        totalSales: response.payload.total_sales,
        storySales: response.payload.story_sales,
        addOnSales: response.payload.addOn_sales,
        renewalSales: response.payload.renewal_sales,
        revenueGraphData: response.payload.revenue,
        salesTableData: response.payload.sales_data,
        defaultSearchData: response.payload.sales_data
      });
    }
  }

  handleDateChange = async (e) => {
    const { name, value } = e.target;
    await this.setState({ [name]: value }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.fetchSalesData();
      }
    });
  }

  fetchSalesData = async () => {
    const response = await this.props.salesDashboard({
      filterType: "specificDates",
      filterDate: {
        "startDate": this.state.startDate,
        "endDate": this.state.endDate
      }
    });
    this.setState({ totalSales: response.payload.total_sales });
    this.setState({ storySales: response.payload.story_sales });
    this.setState({ addOnSales: response.payload.addOn_sales });
    this.setState({ renewalSales: response.payload.renewal_sales });
    this.setState({ revenueGraphData: response.payload.revenue });
    this.setState({ salesTableData: response.payload.sales_data, defaultSearchData: response.payload.sales_data });
  }

  generateExcelData = () => {
    const { salesTableData } = this.state;
    const xlsxData = [
      ['Order Reference No.', 'Customer Name', 'Email', 'Date', 'Revenue Amount']
    ];
    for (const data of salesTableData) {
      for (const item of data.items) {
        const dataRow = [
          item.order_reference_no,
          data.customer_name,
          // item.sales_source,
          data.email,
          // data.phone,
          formatDateAndTime(data.order_date),
          data.revenue_amount
        ];
        xlsxData.push(dataRow);
      }
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, 'sales_data.xlsx');
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  DefaultSearchChange = (e) => {
    const searchData = e.target.value;
    const defaultSearchData = this.state.salesTableData.filter(data => {
      const valuesToSearch = Object.values(data).filter(value => value !== null);
      return valuesToSearch.some(value =>
        String(value).toLowerCase().includes(searchData.toLowerCase())
      );
    });
    this.setState({ searchData, defaultSearchData });
  };

  handleTableSort = (column) => {
    const { defaultSearchData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

    // Sort the data based on the selected column and sortOrder
    const sortedData = defaultSearchData.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      defaultSearchData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  render() {
    const { selectedRange, startDate, endDate, totalSales, storySales, addOnSales, renewalSales, revenueGraphData, defaultSearchData, currentPage, itemsPerPage, sortedColumn, sortOrder } = this.state;
    console.log("sortedColumn===>>>",sortedColumn );
    const doughnutOption = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false,
          color: 'white'
        }
      }
    }
    const doughnutData = {
      labels: [`Story: ${revenueGraphData.story}`, `Add-on: ${revenueGraphData.addon}`, `Renewal: ${revenueGraphData.renewal}`],
      datasets: [{
        data: [revenueGraphData.story, revenueGraphData.addon, revenueGraphData.renewal],
        backgroundColor: ["#745CCD", "#E29024", "#CE514C"]
      }]
    }
    // For Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
    console.log("currentData=====>",currentData);
    const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
    const filterBtn = { border: 'none', background: 'none', color: '#00AAC0' }
    const selectedBtn = {
      backgroundColor: '#00AAC0',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      padding: '5px 10px',
    };
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/reporting-analytics/">Reporting & Analytics Management</Link>
                </li>
                <li className='breadcrumb-item active'>Sales Dashboard</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Sales Dashboard</H5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='4' className='mt-3'></Col>
                      <Col md='4' className='mt-3 mb-3'>
                        <button type='button' style={selectedRange === 'today' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('today')}>Today</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type='button' style={selectedRange === '7d' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('7d')}>7 D</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type='button' style={selectedRange === '30d' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('30d')}>30 D</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type='button' style={selectedRange === '3m' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('3m')}>3 M</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type='button' style={selectedRange === '12m' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('12m')}>12 M</button>&nbsp;&nbsp;|
                      </Col>
                      <Col md='2' className='mb-3'>
                        <Label style={{ fontSize: '12px', color: '#00AAC0', marginTop: '0px' }}>Start Date</Label>
                        <Input type='date' style={{ fontSize: '10px', border: '1px solid #00AAC0', color: '#00AAC0', padding: '5px 0px 5px 5px' }} id='startDate' name='startDate' value={startDate} onChange={this.handleDateChange} />
                      </Col>
                      <Col md='2' className='mb-3'>
                        <Label style={{ fontSize: '12px', color: '#00AAC0', marginTop: '0px' }}>End Date</Label>
                        <Input type='date' style={{ fontSize: '10px', border: '1px solid #00AAC0', color: '#00AAC0', padding: '5px 0px 5px 5px' }} id='endDate' name='endDate' value={endDate} onChange={this.handleDateChange} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md='6'>
                        <Card style={{ border: '1px solid #ccc' }}>
                          <h6 style={{ padding: '10px' }}>Revenue</h6>
                          <CardBody className="chart-block">
                            <Doughnut options={doughnutOption} data={doughnutData} width={717} height={490} />
                          </CardBody>
                          <b style={{ fontSize: '12px', textAlign: 'center', paddingBottom: '5px' }}>Total: {revenueGraphData.total}</b>
                        </Card>
                      </Col>
                      <Col md='6'>
                        <Card className='widget-1' style={{ border: "2px solid #00AAC0" }}>
                          <CardBody>
                            <div className='widget-content'>
                              <div className={`widget-round secondary`}>
                                <div className='bg-round'>
                                  <img src={dollarSales} width="60" height="60" alt='sales'></img>
                                </div>
                              </div>
                              <div>
                                <span className='f-light'>Total Sales</span>
                                <h4 style={{ color: '#00AAC0' }}>{totalSales}</h4>
                              </div>
                            </div>
                            <div className={`font-secondary f-w-500`}>
                              <i className={`icon-arrow-${10 < 50 ? 'down' : 'up'} icon-rotate me-1`} />
                              <span>1000</span>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className='widget-1' style={{ border: "2px solid #745CCD" }}>
                          <CardBody>
                            <div className='widget-content'>
                              <div className={`widget-round secondary`}>
                                <div className='bg-round'>
                                  <img src={dollarStorySales} width="60" height="60" alt='sales'></img>
                                </div>
                              </div>
                              <div>
                                <span className='f-light'>Story Sales</span>
                                <h4 style={{ color: '#745CCD' }}>{storySales}</h4>
                              </div>
                            </div>
                            <div className={`font-secondary f-w-500`}>
                              <i className={`icon-arrow-${10 < 50 ? 'down' : 'up'} icon-rotate me-1`} />
                              <span>1000</span>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className='widget-1' style={{ border: "2px solid #CE514C" }}>
                          <CardBody>
                            <div className='widget-content'>
                              <div className={`widget-round secondary`}>
                                <div className='bg-round'>
                                  <img src={dollarAddOnSales} width="60" height="60" alt='sales'></img>
                                </div>
                              </div>
                              <div>
                                <span className='f-light'>Add-on Sales</span>
                                <h4 style={{ color: '#CE514C' }}>{addOnSales}</h4>
                              </div>
                            </div>
                            <div className={`font-secondary f-w-500`}>
                              <i className={`icon-arrow-${10 < 50 ? 'down' : 'up'} icon-rotate me-1`} />
                              <span>1000</span>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className='widget-1' style={{ border: "2px solid #E29024" }}>
                          <CardBody>
                            <div className='widget-content'>
                              <div className={`widget-round secondary`}>
                                <div className='bg-round'>
                                  <img src={dollarRenewalOnSales} width="60" height="60" alt='sales'></img>
                                </div>
                              </div>
                              <div>
                                <span className='f-light'>Renewal Sales</span>
                                <h4 style={{ color: '#E29024' }}>{renewalSales}</h4>
                              </div>
                            </div>
                            <div className={`font-secondary f-w-500`}>
                              <i className={`icon-arrow-${10 < 50 ? 'down' : 'up'} icon-rotate me-1`} />
                              <span>1000</span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardHeader className='d-flex align-items-center justify-content-between'>
                          <H5>Sales Data</H5>
                          <img src={excelImg} width="35" height="35" alt='excel' onClick={this.handleDownload}></img>
                        </CardHeader>
                        <CardBody>
                          <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                            <thead>
                              <tr>
                                <th onClick={() => this.handleTableSort('order_reference_no')}>Order Reference No. {sortedColumn === 'order_reference_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                <th onClick={() => this.handleTableSort('customer_name')}>Customer Name {sortedColumn === 'customer_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                {/* <th onClick={() => this.handleTableSort('sales_source')}>Sales Source {sortedColumn === 'sales_source' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                                <th onClick={() => this.handleTableSort('email')}>Email {sortedColumn === 'email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                {/* <th onClick={() => this.handleTableSort('phone')}>Phone {sortedColumn === 'phone' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                                <th onClick={() => this.handleTableSort('order_date')}>Date {sortedColumn === 'order_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                <th onClick={() => this.handleTableSort('revenue_amount')}>Revenue Amount {sortedColumn === 'revenue_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentData.map((data, i) => (
                                  // data.items.map((item, j) => (
                                  <tr key={`${i}`}>
                                    <td>{data.order_reference_no}</td>
                                    <td>{data.customer_name}</td>
                                    {/* <td>{data.sales_source}</td> */}
                                    <td>{data.email}</td>
                                    {/* <td>{data.phone}</td> */}
                                    <td>{data.date}</td>
                                    <td>{data.revenue_amount}</td>
                                  </tr>
                                // ))
                              ))}
                            </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  salesDashboard: reportOperations.salesDashboard
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesDashboard)