import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { H5, Btn } from "../../AbstractElements";
import { Row, Col, CardBody, CardFooter, CardHeader, Form, FormGroup, Label, Input, Container, Card } from 'reactstrap';
import { Cancel } from '../../Constant';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { authOperations } from '../../state/ducks/auth';
import { validPassword } from '../validation.jsx';

class ResetPassword extends React.Component {

  constructor() {
    super();
    this.state = {
      isPasswordValid: true
    }
  }

  handleChange = async (e) => {
    try {
      let tid = e.target.id;
      switch (tid) {
        case 'newPassword':
          const newPassword = e.target.value;
          const isPasswordValid = !newPassword || validPassword.test(newPassword);
          this.setState({ newPassword: newPassword, isPasswordValid });
          break;
        default:
          console.log("No Matching target found");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  onInsert = async (event) => {
    event.preventDefault();
    try {
      let user = localStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
        this.setState({ user });
      }
      const payload = {
        id: user.id,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword
      }
      const response = await this.props.resetPassword(payload);
      toast.success(response.message, { autoClose: 10000 });
      window.open("/additional-considerations/", "_self");
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/additional-considerations/">Additional Considerations</Link>
                </li>
                <li className='breadcrumb-item active'>Reset Password</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <div className='edit-profile'>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader className='d-flex align-items-center justify-content-between'>
                    <H5>Reset Password</H5>
                  </CardHeader>
                  <Form onSubmit={this.onInsert}>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3 mt-3">
                            <Label className="form-label">New Password</Label>
                            <Input className='form-control' type='password' placeholder='Enter New Password' name="newPassword" id="newPassword" required value={this.state.newPassword || ''} onChange={this.handleChange} />
                            {this.state.isPasswordValid ? <span></span> : <span style={{ color: "#F61C04" }}>Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit.</span>}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3 mt-3">
                            <Label className="form-label">Confirm Password</Label>
                            <Input type="password" placeholder="Enter Confirm Password" required onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Btn attrBtn={{ color: "primary", type: "submit" }} >Reset</Btn>&nbsp;&nbsp;&nbsp;
                      <Link to="/additional-considerations/">
                        <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                      </Link>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment >
    )
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  resetPassword: authOperations.resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)