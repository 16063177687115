import * as types from "./types";
import * as API_URL from "../../../env";

export const listLanguage = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/language/list-language",
        method: "POST",
        body: payload
    },
});

export const sendEmail = (payload) => ({
    type: types.SEND_EMAIL,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/orders/send-email",
        method: "POST",
        body: payload
    },
});