export const MENUITEMS = [
  {
    Items: [
      { path: `${process.env.PUBLIC_URL}/dashboard`, icon: "home", title: "Dashboard", type: "link" },
      { path: `${process.env.PUBLIC_URL}/customers-management`, icon: "social", title: "Customers-Management", type: "link" },
      { path: `${process.env.PUBLIC_URL}/order-management`, icon: "ecommerce", title: "Order-Management", type: "link" },
      { path: `${process.env.PUBLIC_URL}/product-listing`, icon: "others", title: "Product-Listing", type: "link"},
      { path: `${process.env.PUBLIC_URL}/question-category`, icon: "others", title: "Question-Category", type: "link"},
      { path: `${process.env.PUBLIC_URL}/reporting-analytics`, icon: "task", title: "Reporting-Analytics", type: "link"},
      { path: `${process.env.PUBLIC_URL}/additional-considerations`, icon: "project", title: "Additional-Considerations", type: "link"}
    ]
  }
];