import { login as loginAction, logout as logoutAction, signup, authenticated, initializeSession, destroySession, forgotPassword, confirmOTP, resetPassword, changePassword, fetchListMenusForRole } from "./actions";

const login = (payload) => {
    console.log('payload....',payload);
    return async (dispatch) => {
        let response = await dispatch(loginAction(payload));
        console.log('response....',response);
        let { token, user } = response.payload.data;
        console.log('token....',token);
        console.log('user....',user);
        return { user, token }
    };
}

const logout = (payload) => {
    return async (dispatch) => {
        await dispatch(logoutAction());
        await dispatch(destroySession())
    };
}
export { login, logout, signup, authenticated, initializeSession, forgotPassword, confirmOTP, resetPassword, changePassword, fetchListMenusForRole };