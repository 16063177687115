import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { Container, Card, CardBody, CardHeader, Col, Form, Row, Nav, NavItem, NavLink, TabContent, TabPane, Label, Input } from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import question from '../../assets/images/datatable/question.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { reportOperations } from '../../state/ducks/report';

class QuestionAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: '1',
      selectedRange: 'today',
      startDate: '',
      endDate: '',
      totalQuestion: '',
      mostPopularQuestionsList: [],
      leastPopularuestQionsList: [],
      questionCategoryGraph: [],
      questionGraph: []
    };
  }

  async componentDidMount() {
    try {
      // get today data
      const response = await this.props.questionDashboard({ filterType: "days", filterDate: "today" });
      this.setState({ totalQuestion: response.payload.total_question });
      this.setState({ mostPopularQuestionsList: response.payload.most_popular_questions_list });
      this.setState({ leastPopularuestQionsList: response.payload.least_popular_questions_list });
      this.setState({ questionCategoryGraph: response.payload.question_category_graph });
      this.setState({ questionGraph: response.payload.question_graph });
    } catch (err) {
      console.log(err);
    }
  }

  handleRangeChange = async (range) => {
    this.setState({ selectedRange: range, startDate: '', endDate: '' });
    if (['today', '7d', '30d'].includes(range)) {
      const response = await this.props.questionDashboard({ filterType: "days", filterDate: range });
      this.setState({ totalQuestion: response.payload.total_question });
      this.setState({ mostPopularQuestionsList: response.payload.most_popular_questions_list });
      this.setState({ leastPopularuestQionsList: response.payload.least_popular_questions_list });
      this.setState({ questionCategoryGraph: response.payload.question_category_graph });
      this.setState({ questionGraph: response.payload.question_graph });
    } else if (['3m', '12m'].includes(range)) {
      const response = await this.props.questionDashboard({ filterType: "months", filterDate: range });
      this.setState({ totalQuestion: response.payload.total_question });
      this.setState({ mostPopularQuestionsList: response.payload.most_popular_questions_list });
      this.setState({ leastPopularuestQionsList: response.payload.least_popular_questions_list });
      this.setState({ questionCategoryGraph: response.payload.question_category_graph });
      this.setState({ questionGraph: response.payload.question_graph });
    }
  }

  handleDateChange = async (e) => {
    const { name, value } = e.target;
    await this.setState({ [name]: value }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.fetchData();
      }
    });
  }

  fetchData = async () => {
    const response = await this.props.questionDashboard({
      filterType: "specificDates",
      filterDate: {
        "startDate": this.state.startDate,
        "endDate": this.state.endDate
      }
    });
    this.setState({ totalQuestion: response.payload.total_question });
    this.setState({ mostPopularQuestionsList: response.payload.most_popular_questions_list });
    this.setState({ leastPopularuestQionsList: response.payload.least_popular_questions_list });
    this.setState({ questionCategoryGraph: response.payload.question_category_graph });
    this.setState({ questionGraph: response.payload.question_graph });
  }

  render() {
    const { BasicTab, selectedRange, startDate, endDate, totalQuestion, mostPopularQuestionsList, leastPopularuestQionsList, questionCategoryGraph, questionGraph } = this.state;
    const categories1 = Object.keys(questionCategoryGraph);
    const data1 = categories1.map(category => questionCategoryGraph[category].users);
    const categories2 = Object.keys(questionGraph);
    const data2 = categories2.map(category => questionGraph[category].users);
    const questionCategoryOptions = {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#00AAC0"],
      xaxis: {
        categories: categories1,
      }
    }
    const questionCategorySeries = [{ data: data1 }]
    const questionOptions = {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#00AAC0"],
      xaxis: {
        categories: categories2,
      }
    }
    const questionSeries = [{ data: data2 }]
    const filterBtn = { border: 'none', background: 'none', color: '#00AAC0' }
    const selectedBtn = {
      backgroundColor: '#00AAC0',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      padding: '5px 10px',
    };
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/reporting-analytics/">Reporting & Analytics Management</Link>
                </li>
                <li className='breadcrumb-item active'>Question Statistics</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Question Statistics</H5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='12' style={{ border: "1px solid #999999", borderRadius: '5px', paddingTop: '2%' }}>
                        <H6>Account Overview</H6>
                        <Col md='4'>
                          <Card className='widget-1' style={{ background: "#00AAC0" }}>
                            <CardBody>
                              <div className='widget-content'>
                                <div style={{ color: '#fff' }}>
                                  <span>Total Question</span>
                                  <h4>{totalQuestion}</h4>
                                </div>
                                <div>
                                  <img src={question} width="60" height="60" alt='question' style={{ borderRadius: '30px', marginLeft: '80%' }}></img>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <CardBody style={{ border: "1px solid #999999", borderRadius: '5px', margin: '2% 0% 2% 0%' }}>
                        <Nav tabs>
                          <NavItem>
                            <NavLink href='#javascript' className={BasicTab === '1' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '1' })}>
                              Question Category
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href='#javascript' className={BasicTab === '2' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '2' })}>
                              Question
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={BasicTab}>
                          <TabPane className='fade show' tabId='1'>
                            <Row>
                              <Col md='4' className='mt-3'></Col>
                              <Col md='4' className='mt-3 mb-3'>
                                <button type='button' style={selectedRange === 'today' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('today')}>Today</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <button type='button' style={selectedRange === '7d' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('7d')}>7 D</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <button type='button' style={selectedRange === '30d' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('30d')}>30 D</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <button type='button' style={selectedRange === '3m' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('3m')}>3 M</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <button type='button' style={selectedRange === '12m' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('12m')}>12 M</button>
                              </Col>
                              <Col md='2' className='mb-3'>
                                <Label style={{ fontSize: '12px', color: '#00AAC0', marginTop: '0px' }}>Start Date</Label>
                                <Input type='date' style={{ fontSize: '10px', border: '1px solid #00AAC0', color: '#00AAC0', padding: '5px 0px 5px 5px' }} id='startDate' name='startDate' value={startDate} onChange={this.handleDateChange} />
                              </Col>
                              <Col md='2' className='mb-3'>
                                <Label style={{ fontSize: '12px', color: '#00AAC0', marginTop: '0px' }}>End Date</Label>
                                <Input type='date' style={{ fontSize: '10px', border: '1px solid #00AAC0', color: '#00AAC0', padding: '5px 0px 5px 5px' }} id='endDate' name='endDate' value={endDate} onChange={this.handleDateChange} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md='12'>
                                <div id='basic-bar'>
                                  <Chart options={questionCategoryOptions} series={questionCategorySeries} type="bar" height={350} />
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId='2'>
                            <Row>
                              <Col md='4' className='mt-3'></Col>
                              <Col md='4' className='mt-3 mb-3'>
                                <button type='button' style={selectedRange === 'today' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('today')}>Today</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <button type='button' style={selectedRange === '7d' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('7d')}>7 D</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <button type='button' style={selectedRange === '30d' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('30d')}>30 D</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <button type='button' style={selectedRange === '3m' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('3m')}>3 M</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <button type='button' style={selectedRange === '12m' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('12m')}>12 M</button>
                              </Col>
                              <Col md='2' className='mb-3'>
                                <Label style={{ fontSize: '12px', color: '#00AAC0', marginTop: '0px' }}>Start Date</Label>
                                <Input type='date' style={{ fontSize: '10px', border: '1px solid #00AAC0', color: '#00AAC0', padding: '5px 0px 5px 5px' }} id='startDate' name='startDate' value={startDate} onChange={this.handleDateChange} />
                              </Col>
                              <Col md='2' className='mb-3'>
                                <Label style={{ fontSize: '12px', color: '#00AAC0', marginTop: '0px' }}>End Date</Label>
                                <Input type='date' style={{ fontSize: '10px', border: '1px solid #00AAC0', color: '#00AAC0', padding: '5px 0px 5px 5px' }} id='endDate' name='endDate' value={endDate} onChange={this.handleDateChange} />
                              </Col>
                            </Row>
                            <Row>
                              <Col md='12'>
                                <div id='basic-bar'>
                                  <Chart options={questionOptions} series={questionSeries} type="bar" height={350} />
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Row>
                  </Form>
                  <Row>
                    <Col sm="12" style={{ border: "1px solid #999999", borderRadius: '5px', padding: '2%' }}>
                      <H6>Most Popular Question List</H6>
                      {mostPopularQuestionsList.map((data, i) =>
                        <div key={i} style={{ background: "#f2f2f2", border: "1px solid #ccc", padding: '3% 0% 3% 1%' }}>
                          <span style={{ color: '#00AAC0', fontWeight: 'bold', fontSize: '12px' }}>{data.question}</span>
                          <span style={{ float: 'right', color: '#00b300', background: '#ccffcc', fontSize: '10px', padding: '1px', marginRight: '1%' }}>{data.userCount}</span>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col sm="12" style={{ border: "1px solid #999999", borderRadius: '5px', padding: '2%' }}>
                      <H6>Least Popular Question List</H6>
                      {leastPopularuestQionsList.map((data, i) =>
                        <div key={i} style={{ background: "#f2f2f2", border: "1px solid #ccc", padding: '3% 0% 3% 1%' }}>
                          <span style={{ color: '#00AAC0', fontWeight: 'bold', fontSize: '12px' }}>{data.question}</span>
                          <span style={{ float: 'right', color: '#00b300', background: '#ccffcc', fontSize: '10px', padding: '1px', marginRight: '1%' }}>{data.userCount}</span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  questionDashboard: reportOperations.questionDashboard
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAnalytics)