export const SALES_DASHBOARD = "report/SALES_DASHBOARD";
export const SALES_DASHBOARD_COMPLETED = "report/SALES_DASHBOARD_COMPLETED";
export const SALES_DASHBOARD_FAILED = "report/SALES_DASHBOARD_FAILED";

export const CUSTOMER_DASHBOARD = "report/CUSTOMER_DASHBOARD";
export const CUSTOMER_DASHBOARD_COMPLETED = "report/CUSTOMER_DASHBOARD_COMPLETED";
export const CUSTOMER_DASHBOARD_FAILED = "report/CUSTOMER_DASHBOARD_FAILED";

export const QUESTION_DASHBOARD = "report/QUESTION_DASHBOARD";
export const QUESTION_DASHBOARD_COMPLETED = "report/QUESTION_DASHBOARD_COMPLETED";
export const QUESTION_DASHBOARD_FAILED = "report/QUESTION_DASHBOARD_FAILED";