import { Col, Row, Table, CardBody, Card, Media, CardFooter } from 'reactstrap';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { H3, H4, H6, P, Image, Btn } from '../../AbstractElements';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import logo from '../../assets/images/logo/logo.png';
import user1 from '../../assets/images/user/1.jpg';

class CustomerReceipt extends Component {

  render() {
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/customers-management/">Customers</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/customers-management/customer-details/">Customer Details</Link>
                </li>
                <li className='breadcrumb-item active'>Receipt</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Col sm='12' xl='12' className='xl-100'>
          <Card>
            <CardBody>
              <div className='invoice'>
                <div>
                  <Row className='invo-header'>
                    <Col sm='6'>
                      <Media>
                        <div className='media-left'>
                          <Image attrImage={{ className: 'media-object img-120', src: logo, alt: '' }} /><br></br>
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          <span>Coalesce Tech Solutions Pvt Ltd</span><br></br>
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          <span>Signature 2 Internal Rd, Sarkhej, Ahmedabad, Gujarat 382210</span>
                        </div>
                      </Media>
                    </Col>
                    <Col sm='6'>
                      <div className='text-md-end text-xs-center'>
                        <H3>Kinjal</H3>
                        <P>Receipt No.: #123<br />Payment Date: <span className='digits'> 10 April, 2024</span></P>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row className='invo-profile'>
                    <Col xl='4'>
                      <div className='invo-profile-left'>
                        <Media>
                          <div className='media-left'>
                            <Image attrImage={{ className: 'media-object rounded-circle img-60', src: `${user1}`, alt: '' }} />
                          </div>
                          <Media body className='m-l-20'>
                            <H4 attrH4={{ className: 'media-heading f-w-600' }}>Johan Deo</H4>
                            <P><span className='digits'>555-555-5555</span></P>
                          </Media>
                        </Media>
                      </div>
                    </Col>
                    <Col xl='8'>
                      <div className='text-md-end'>
                        <H6>Project Description</H6>
                        <P>Project Description Details</P>
                      </div>
                    </Col>
                  </Row>
                  <div className='table-responsive invoice-table' id='table'>
                    <Table bordered striped>
                      <tbody>
                        <tr>
                          <td className='item'>
                            <H6 attrH6={{ className: 'p-2 mb-0' }}>Item Name</H6>
                          </td>
                          <td className='quantity'>
                            <H6 attrH6={{ className: 'p-2 mb-0' }}>Reference No.</H6>
                          </td>
                          <td className='Rate'>
                            <H6 attrH6={{ className: 'p-2 mb-0' }}>Amount</H6>
                          </td>
                        </tr>
                        <tr>
                          <td>Book One</td>
                          <td>17525001</td>
                          <td>$120</td>
                        </tr>
                        <tr>
                          <td>Book Two</td>
                          <td>17525002</td>
                          <td>$150</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <Row className="mt-3">
                    <Col md="8"></Col>
                    <Col md="4">
                      <div className='text-md-end'>
                        <P><b>Sub Total:</b> $270</P>
                      </div>
                      <div className='text-md-end'>
                        <P><b>Total:</b> $270</P>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <Col sm="12" className="text-center">
                <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Download</Btn>
                <Link to={`${process.env.PUBLIC_URL}/customers-management/customer-details/`}>
                  <Btn attrBtn={{ color: 'primary' }}>Cancel</Btn>
                </Link>
              </Col>
            </CardFooter>
          </Card>
        </Col>
      </Fragment>
    );
  }
}

export default CustomerReceipt;