import * as types from "./types";
import * as API_URL from "../../../env";

export const listOrder = (payload) => ({
    type: types.FETCH_ORDER_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/orders/list-orders",
        method: "POST",
        body: payload
    },
});

export const listOrderDetails = (payload) => ({
    type: types.FETCH_ORDER_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/orders/get-order-details",
        method: "POST",
        body: payload
    },
});