import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { H5, Btn } from "../../AbstractElements";
import { Row, Col, CardBody, CardFooter, CardHeader, Form, FormGroup, Label, Input, Container, Card } from 'reactstrap';
import { Update, Cancel } from '../../Constant';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { categoryOperations } from '../../state/ducks/category';

class CategoryEdit extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedCategoryData: {}
    }
  }

  async componentDidMount() {
    const categoryId = localStorage.getItem("selectedCategoryId");
    const response = await this.props.listCategoryDetails({ "categoryId": categoryId });
    this.setState({ selectedCategoryData: response.payload });
  }

  handleInput = async (e) => {
    try {
      let tid = e.target.id;
      const { selectedCategoryData } = this.state;
      switch (tid) {
        case 'category_name':
          const category_name = e.target.value;
          this.setState({
            selectedCategoryData: {
              ...selectedCategoryData,
              category_name
            }
          });
          break;
        case 'status':
          const status = e.target.value;
          this.setState({
            selectedCategoryData: {
              ...selectedCategoryData,
              status
            }
          });
          break;
        case 'notes':
          const notes = e.target.value;
          this.setState({
            selectedCategoryData: {
              ...selectedCategoryData,
              notes
            }
          });
          break;
        default:
          console.log("No Matching target found in handleInput");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  }

  onUpdate = async (e) => {
    e.preventDefault()
    try {
      const { selectedCategoryData } = this.state;
      let isStatus;
      if (selectedCategoryData.status === 'active') {
        isStatus = 1;
      } else if (selectedCategoryData.status === 'inactive') {
        isStatus = 0;
      }
      let updateResponse = await this.props.updateCategory({
        id: selectedCategoryData.id,
        category_title: selectedCategoryData.category_name,
        status: isStatus,
        notes: selectedCategoryData.notes
      });
      toast.success(updateResponse.message, { autoClose: 10000 });
      window.open("/question-category/", "_self");
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  render() {
    const { selectedCategoryData } = this.state;
    return (
      <Fragment>
        <ToastContainer />
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/question-category/">Question Category</Link>
                </li>
                <li className='breadcrumb-item active'>Edit Category</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <div className='edit-profile'>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader className='d-flex align-items-center justify-content-between'>
                    <H5>Edit Category</H5>
                  </CardHeader>
                  <Form onSubmit={this.onUpdate}>
                    <CardBody>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3 mt-3">
                            <Label className="form-label">Category Title</Label>
                            <Input type="text" name="category_name" id="category_name" placeholder="Enter Category Title" value={selectedCategoryData.category_name || ''} onChange={this.handleInput} />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label className="form-label">Category Status</Label>
                            <Input type="select" name="status" id="status" value={selectedCategoryData.status || ''} onChange={this.handleInput}>
                              <option value=''>Select Status</option>
                              <option value='active'>Active</option>
                              <option value='inactive'>Inactive</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label className="form-label">Category Notes(Internal)</Label>
                            <Input type="textarea" placeholder="Enter Category Notes" id="notes" name="notes" value={selectedCategoryData.notes || ''} onChange={this.handleInput} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Btn attrBtn={{ color: "primary", type: "submit" }} >{Update}</Btn>&nbsp;&nbsp;&nbsp;
                      <Link to="/question-category/">
                        <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                      </Link>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment >
    )
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  listCategoryDetails: categoryOperations.listCategoryDetails,
  updateCategory: categoryOperations.updateCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEdit)