export const FETCH_QUESTION_LIST = "question/FETCH_QUESTION_LIST";
export const FETCH_QUESTION_LIST_COMPLETED = "question/FETCH_QUESTION_LIST_COMPLETED";
export const FETCH_QUESTION_LIST_FAILED = "question/FETCH_QUESTION_LIST_FAILED";

export const INSERT_QUESTION = "question/INSERT_QUESTION";
export const INSERT_QUESTION_COMPLETED = "question/INSERT_QUESTION_COMPLETED";
export const INSERT_QUESTION_FAILED = "question/INSERT_QUESTION_FAILED";

export const UPDATE_QUESTION = "question/UPDATE_QUESTION";
export const UPDATE_QUESTION_COMPLETED = "question/UPDATE_QUESTION_COMPLETED";
export const UPDATE_QUESTION_FAILED = "question/UPDATE_QUESTION_FAILED";

export const DELETE_QUESTION = "question/DELETE_QUESTION";
export const DELETE_QUESTION_COMPLETED = "question/DELETE_QUESTION_COMPLETED";
export const DELETE_QUESTION_FAILED = "question/DELETE_QUESTION_FAILED";