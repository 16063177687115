import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LogIn } from 'react-feather';
import { UL, P } from '../../../AbstractElements';
import { LogOut } from '../../../Constant';
import { connect } from 'react-redux';
// import { roleOperations } from '../../../state/ducks/role';
// import { getRoleName } from '../../../utils/helper';

class RightHeader extends React.Component {

  constructor() {
    super();
    this.state = {
      user: [],
      displayName: '',
      display_f_name: '',
      // role_name: '',
      imgUrl: ''
    }
  }

  async componentDidMount() {
    // this.loadLocalStorageData();
    let user = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      this.setState({ user });
    }
    if (typeof user === 'undefined') {
    } else {
      if (user && typeof user.name === 'undefined') {
      } else {
        const proHeadName = user.name.charAt(0) + user.name.charAt(1);
        const displayFirstName = user.name;
        const photo_url = user.photo_url;
        this.setState({ displayName: proHeadName });
        this.setState({ display_f_name: displayFirstName });
        this.setState({ imgUrl: photo_url });
      }
      // const role_name = "Role: " + getRoleName(user.role_id);
      // this.setState({ role_name: role_name });
    }
  }

  // loadLocalStorageData = async () => {
  //   try {
  //     let roles = localStorage.getItem('roles');
  //     if (roles) {
  //       roles = JSON.parse(roles);
  //       this.setState({ roles });
  //     } else {
  //       const response = await this.props.listRoles();
  //       if (response.payload.length !== 0) {
  //         const rolesString = JSON.stringify(response.payload);
  //         localStorage.setItem('roles', rolesString);
  //         roles = JSON.parse(rolesString);
  //         this.setState({ roles });
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error loading data:", error.message);
  //   }
  // }

  render() {
    var font = { fontSize: "12px", fontFamily: "Rubik, sans-serif" }
    var divStyle  = {
      height: '40px',
      width: '40px', 
      borderRadius: '50%',
      background: '#890077',
      paddingTop: "8px"
    };
    var spanStyle  = {
      fontSize: "18px", 
      fontWeight: "bold",
      color: '#fff',
      paddingRight: "0.5rem", 
      paddingLeft: "0.5rem",
      textTransform: "uppercase"
    };
    return (
      <Fragment>
        <Col xxl='7' xl='6' md='7' className='nav-right pull-right right-header col-8 p-0 ms-auto'>
          <UL attrUL={{ className: 'simple-list nav-menus flex-row' }}>
            <li className='profile-nav onhover-dropdown pe-0 py-0'>
              <div className='media profile-media'>
                <div style={divStyle}>
                  <span style={spanStyle}>{this.state.displayName}</span>
                </div>
                <div style={{ "marginLeft": "14px" }} className='media-body'>
                  <span>{this.state.display_f_name}</span>
                  <P attrPara={{ className: 'mb-0 font-roboto' }}>
                    Admin &nbsp;<i className='middle fa fa-angle-down'></i>
                  </P>
                </div>
              </div>
              <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
                {/* <li style={font}>
                  <Link to="/my-profile/">
                    <User />
                    <span>{MyProfile}</span>
                  </Link>
                </li>
                <li style={font}>
                  <Link to="/change-password/">
                    <Mail />
                    <span>Change Password</span>
                  </Link>
                </li> */}
                <li style={font}>
                  <Link to="/logout/">
                    <LogIn />
                    <span>{LogOut}</span>
                  </Link>
                </li>
              </UL>
            </li>
          </UL>
        </Col>
      </Fragment>
    );
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  // listRoles: roleOperations.listRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(RightHeader)