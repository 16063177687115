import * as types from "./types";
import * as API_URL from "../../../env";

export const listCustomer = (payload) => ({
    type: types.FETCH_CUSTOMER_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/list-users",
        method: "POST",
        body: payload
    },
});

export const listCustomerDetails = (payload) => ({
    type: types.FETCH_CUSTOMER_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/get-user-details",
        method: "POST",
        body: payload
    },
});

export const updateNote = (note) => ({
    type: types.UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/update-user-note",
        method: "PUT",
        body: note
    },
});