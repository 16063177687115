import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import salesDashboard from '../../assets/images/datatable/salesDashboard.png';
import customerDashboard from '../../assets/images/datatable/customerDashboard.png';
import questionDashboard from '../../assets/images/datatable/questionDashboard.png';
import orderPrintDashboard from '../../assets/images/datatable/orderPrintDashboard.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

class Reporting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickSalesDashboard = () => {
    window.open("/sales-report/", "_self");
  }

  onClickCustomerDashboard = () => {
    window.open("/reporting-analytics/customer-report/", "_self");
  }

  onClickQuestionAnalytics = () => {
    window.open("/reporting-analytics/question-analytics/", "_self");
  }

  render() {
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item active'>Reporting & Analytics Management</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Reporting & Analytics Management</H5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='6'>
                        <Card style={{ border: "1px solid #ccc" }} onClick={this.onClickSalesDashboard}>
                          <Row>
                            <Col xs={4}>
                              <div style={{ padding: "5%", textAlign: "center" }}>
                                <img src={salesDashboard} alt='edit' height={100} width={100} />
                              </div>
                            </Col>
                            <Col xs={8}>
                              <div style={{ color: '#890077', fontWeight: 'bold', padding: '5% 0% 10% 5%' }}>Sales Dashboard</div>
                              <span className='badge' style={{ backgroundColor: '#e6e6ff', color: '#0000ff', marginLeft: '5%', fontSize: '9px' }}>Sales</span>
                              {/* <span className='badge badge-light-info' style={{ fontSize: '9px' }}>Revenue</span> */}
                              <span className='badge badge-light-primary' style={{ fontSize: '9px' }}>Product Specific</span>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col md='6'>
                        <Card style={{ border: "1px solid #ccc" }} onClick={this.onClickCustomerDashboard}>
                          <Row>
                            <Col xs={4}>
                              <div style={{ padding: "5%", textAlign: "center" }}>
                                <img src={customerDashboard} alt='edit' height={100} width={100} />
                              </div>
                            </Col>
                            <Col xs={8}>
                              <div style={{ color: '#890077', fontWeight: 'bold', padding: '5% 0% 10% 5%' }}>Customer Dashboard</div>
                              <span className='badge' style={{ backgroundColor: '#e6e6ff', color: '#0000ff', marginLeft: '5%', fontSize: '9px' }}>New Customer</span>
                              <span className='badge badge-light-primary' style={{ fontSize: '9px' }}>Retain Customer</span>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col md='6'>
                        <Card style={{ border: "1px solid #ccc" }} onClick={this.onClickQuestionAnalytics}>
                          <Row>
                            <Col xs={4}>
                              <div style={{ padding: "5%", textAlign: "center" }}>
                                <img src={questionDashboard} alt='edit' height={100} width={100} />
                              </div>
                            </Col>
                            <Col xs={8}>
                              <div style={{ color: '#890077', fontWeight: 'bold', padding: '5% 0% 10% 5%' }}>Question Analytics</div>
                              <span className='badge' style={{ backgroundColor: '#e6e6ff', color: '#0000ff', fontSize: '9px', marginLeft: '5%' }}>Most Popular</span>
                              <span className='badge badge-light-info' style={{ fontSize: '9px' }}>Answer Ratio</span>
                              <span className='badge badge-light-primary' style={{ fontSize: '9px' }}>Non Answer Ratio</span>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* <Col md='6'>
                        <Card style={{ border: "1px solid #ccc" }}>
                          <Row>
                            <Col xs={4}>
                              <div style={{ padding: "5%", textAlign: "center" }}>
                                <img src={orderPrintDashboard} alt='edit' height={100} width={100} />
                              </div>
                            </Col>
                            <Col xs={8}>
                              <div style={{ color: '#890077', fontWeight: 'bold', padding: '5% 0% 10% 5%' }}>Order/Print Book Analytics</div>
                              <span className='badge' style={{ backgroundColor: '#e6e6ff', color: '#0000ff', fontSize: '9px', marginLeft: '5%' }}>Howmany Order</span>
                              <span className='badge badge-light-info' style={{ fontSize: '9px' }}>Mot Order</span>
                              <span className='badge badge-light-primary' style={{ fontSize: '9px' }}>Repeat Order</span>
                            </Col>
                          </Row>
                        </Card>
                      </Col> */}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Reporting;