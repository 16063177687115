import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Line } from "react-chartjs-2";
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { Table } from 'react-bootstrap';
import { Container, Card, CardBody, CardHeader, CardFooter, Col, Form, Row, Label, Input } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import excelImg from '../../assets/images/datatable/excel.png';
import customer from '../../assets/images/datatable/customer.png';
import user from '../../assets/images/datatable/user.png';
import existing from '../../assets/images/datatable/existing.png';
import activeUserIcon from '../../assets/images/datatable//active-user.png';
import inactiveUserIcon from '../../assets/images/datatable/inactive-user.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { reportOperations } from '../../state/ducks/report';
import Pagination from '../Pagination';
import '../../assets/scss/pagination.css';
import { formatDateAndTime } from '../../utils/helper';
import "../../Data/Chart/chartjs";

class CustomerDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRange: 'today',
      startDate: '',
      endDate: '',
      totalCustomer: '',
      newUser: '',
      existingUser: '',
      activeUser: '',
      inactiveUser: '',
      customerStatistics: {},
      customerDetails: [],
      defaultSearchData: [],
      searchData: '',
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: 'asc',
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            display: false,
            color: 'white'
          }
        }
      }
    }
  }

  async componentDidMount() {
    try {
      // get today data
      const response = await this.props.customerDashboard({ filterType: "days", filterDate: "today" });
      this.setState({ totalCustomer: response.payload.total_counts.total_customer });
      this.setState({ newUser: response.payload.total_counts.new_user });
      this.setState({ existingUser: response.payload.total_counts.existing_user });
      this.setState({ activeUser: response.payload.total_counts.active_user });
      this.setState({ inactiveUser: response.payload.total_counts.inactive_user });
      this.setState({ customerStatistics: response.payload.total_counts.customer_statistics });
      this.setState({ customerDetails: response.payload.customer_details, defaultSearchData: response.payload.customer_details });
    } catch (err) {
      console.log(err);
    }
  }

  handleRangeChange = async (range) => {
    this.setState({ selectedRange: range, startDate: '', endDate: '' });
    if (['today', '7d', '30d'].includes(range)) {
      const response = await this.props.customerDashboard({ filterType: "days", filterDate: range });
      this.setState({ totalCustomer: response.payload.total_counts.total_customer });
      this.setState({ newUser: response.payload.total_counts.new_user });
      this.setState({ existingUser: response.payload.total_counts.existing_user });
      this.setState({ activeUser: response.payload.total_counts.active_user });
      this.setState({ inactiveUser: response.payload.total_counts.inactive_user });
      this.setState({ customerStatistics: response.payload.total_counts.customer_statistics });
      this.setState({ customerDetails: response.payload.customer_details, defaultSearchData: response.payload.customer_details });
    } else if (['3m', '12m'].includes(range)) {
      const response = await this.props.customerDashboard({ filterType: "months", filterDate: range });
      this.setState({ totalCustomer: response.payload.total_counts.total_customer });
      this.setState({ newUser: response.payload.total_counts.new_user });
      this.setState({ existingUser: response.payload.total_counts.existing_user });
      this.setState({ activeUser: response.payload.total_counts.active_user });
      this.setState({ inactiveUser: response.payload.total_counts.inactive_user });
      this.setState({ customerStatistics: response.payload.total_counts.customer_statistics });
      this.setState({ customerDetails: response.payload.customer_details, defaultSearchData: response.payload.customer_details });
    }
  }

  handleDateChange = async (e) => {
    const { name, value } = e.target;
    await this.setState({ [name]: value }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.fetchData();
      }
    });
  }

  fetchData = async () => {
    const response = await this.props.customerDashboard({
      filterType: "specificDates",
      filterDate: {
        "startDate": this.state.startDate,
        "endDate": this.state.endDate
      }
    });
    this.setState({ totalCustomer: response.payload.total_counts.total_customer });
    this.setState({ newUser: response.payload.total_counts.new_user });
    this.setState({ existingUser: response.payload.total_counts.existing_user });
    this.setState({ activeUser: response.payload.total_counts.active_user });
    this.setState({ inactiveUser: response.payload.total_counts.inactive_user });
    this.setState({ customerStatistics: response.payload.total_counts.customer_statistics });
    this.setState({ customerDetails: response.payload.customer_details, defaultSearchData: response.payload.customer_details });
  }

  generateExcelData = () => {
    const { customerDetails } = this.state;
    const xlsxData = [
      ['Customer Reference No.', 'Customer Name', 'Customer Activity', 'Email', 'No of Product', 'Date', 'Revenue Amount']
    ];
    for (const data of customerDetails) {
      const dataRow = [
        data.customer_reference_no,
        data.customer_name,
        data.customer_activity,
        data.customer_email,
        data.number_of_product,
        // data.phone_no,
        formatDateAndTime(data.date),
        data.revenue
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, 'customer_data.xlsx');
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  DefaultSearchChange = (e) => {
    const searchData = e.target.value;
    const defaultSearchData = this.state.customerDetails.filter(data => {
      const valuesToSearch = Object.values(data).filter(value => value !== null);
      return valuesToSearch.some(value =>
        String(value).toLowerCase().includes(searchData.toLowerCase())
      );
    });
    this.setState({ searchData, defaultSearchData });
  };

  handleTableSort = (column) => {
    const { defaultSearchData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

    // Sort the data based on the selected column and sortOrder
    const sortedData = defaultSearchData.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      defaultSearchData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  render() {
    const { selectedRange, startDate, endDate, options, totalCustomer, newUser, existingUser, activeUser, inactiveUser, customerStatistics, defaultSearchData, currentPage, itemsPerPage, sortedColumn, sortOrder } = this.state;
    // For Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
    const labels = Object.keys(customerStatistics);
    const lineChartData = {
      labels: labels,
      datasets: [{
        label: 'Inactive Users',
        backgroundColor: 'rgba(113, 113, 113, 0.2)',
        strokeColor: "#00AAC0",
        pointColor: "#00AAC0",
        data: labels.map(month => customerStatistics[month].inactive_users)
      },
      {
        label: 'Active Users',
        backgroundColor: 'rgb(36, 105, 92, 0.2)',
        borderColor: '#A020F0',
        pointColor: '#A020F0',
        data: labels.map(month => customerStatistics[month].active_users)
      }]
    }
    const filterBtn = { border: 'none', background: 'none', color: '#00AAC0' }
    const selectedBtn = {
      backgroundColor: '#00AAC0',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      padding: '5px 10px',
    };
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/reporting-analytics/">Reporting & Analytics Management</Link>
                </li>
                <li className='breadcrumb-item active'>Customer Dashboard</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Customer Dashboard</H5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='4' className='mt-3'></Col>
                      <Col md='4' className='mt-3 mb-3'>
                        <button type='button' style={selectedRange === 'today' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('today')}>Today</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type='button' style={selectedRange === '7d' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('7d')}>7 D</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type='button' style={selectedRange === '30d' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('30d')}>30 D</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type='button' style={selectedRange === '3m' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('3m')}>3 M</button>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <button type='button' style={selectedRange === '12m' ? selectedBtn : filterBtn} onClick={() => this.handleRangeChange('12m')}>12 M</button>&nbsp;&nbsp;|
                      </Col>
                      <Col md='2' className='mb-3'>
                        <Label style={{ fontSize: '12px', color: '#00AAC0', marginTop: '0px' }}>Start Date</Label>
                        <Input type='date' style={{ fontSize: '10px', border: '1px solid #00AAC0', color: '#00AAC0', padding: '5px 0px 5px 5px' }} id='startDate' name='startDate' value={startDate} onChange={this.handleDateChange} />
                      </Col>
                      <Col md='2' className='mb-3'>
                        <Label style={{ fontSize: '12px', color: '#00AAC0', marginTop: '0px' }}>End Date</Label>
                        <Input type='date' style={{ fontSize: '10px', border: '1px solid #00AAC0', color: '#00AAC0', padding: '5px 0px 5px 5px' }} id='endDate' name='endDate' value={endDate} onChange={this.handleDateChange} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md='4'>
                        <Card className='widget-1' style={{ border: "2px solid #00AAC0" }}>
                          <CardBody>
                            <div className='widget-content'>
                              <div className={`widget-round secondary`}>
                                <div className='bg-round'>
                                  <img src={customer} width="60" height="60" alt='customer'></img>
                                </div>
                              </div>
                              <div>
                                <span className='f-light'>Total Customer</span>
                                <h4 style={{ color: '#00AAC0' }}>{totalCustomer}</h4>
                              </div>
                            </div>
                            <div className={`font-secondary f-w-500`}>
                              <i className={`icon-arrow-${10 < 50 ? 'down' : 'up'} icon-rotate me-1`} />
                              <span>1000</span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md='4'>
                        <Card className='widget-1' style={{ border: "2px solid #176701" }}>
                          <CardBody>
                            <div className='widget-content'>
                              <div className={`widget-round secondary`}>
                                <div className='bg-round'>
                                  <img src={user} width="60" height="60" alt='user'></img>
                                </div>
                              </div>
                              <div>
                                <span className='f-light'>New User</span>
                                <h4 style={{ color: '#176701' }}>{newUser}</h4>
                              </div>
                            </div>
                            <div className={`font-secondary f-w-500`}>
                              <i className={`icon-arrow-${10 < 50 ? 'down' : 'up'} icon-rotate me-1`} />
                              <span>1000</span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md='4'>
                        <Card className='widget-1' style={{ border: "2px solid #890077" }}>
                          <CardBody>
                            <div className='widget-content'>
                              <div className={`widget-round secondary`}>
                                <div className='bg-round'>
                                  <img src={existing} width="60" height="60" alt='existing'></img>
                                </div>
                              </div>
                              <div>
                                <span className='f-light'>Existing User</span>
                                <h4 style={{ color: '#890077' }}>{existingUser}</h4>
                              </div>
                            </div>
                            <div className={`font-secondary f-w-500`}>
                              <i className={`icon-arrow-${10 < 50 ? 'down' : 'up'} icon-rotate me-1`} />
                              <span>1000</span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='8'>
                        <Card>
                          <h6 style={{ padding: '10px' }}>Customer Statistics</h6>
                          <CardBody className="chart-block">
                            <Line data={lineChartData} options={options} width={778} height={400} />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md='4'>
                        <Card className='widget-hover' style={{ background: "#f5e7fd" }}>
                          <CardBody className='radial-progress-card'>
                            <div>
                              <span className='f-12 f-light f-w-500' style={{ color: '#A020F0' }}>Active User</span>
                              <H5>{activeUser}</H5>
                            </div>
                            <div>
                              <img src={activeUserIcon} width="40" height="40" alt='existing' style={{ borderRadius: "20px" }}></img>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className='widget-hover' style={{ background: "#e6fcff" }}>
                          <CardBody className='radial-progress-card'>
                            <div>
                              <span className='f-12 f-light f-w-500' style={{ color: '#00AAC0' }}>Inactive User</span>
                              <H5>{inactiveUser}</H5>
                            </div>
                            <div>
                              <img src={inactiveUserIcon} width="40" height="40" alt='existing' style={{ borderRadius: "20px" }}></img>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardHeader className='d-flex align-items-center justify-content-between'>
                          <H5>Customer Data</H5>
                          <img src={excelImg} width="35" height="35" alt='excel' onClick={this.handleDownload}></img>
                        </CardHeader>
                        <CardBody>
                          <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                            <thead>
                              <tr>
                                <th onClick={() => this.handleTableSort('customer_reference_no')}>Customer Reference No. {sortedColumn === 'customer_reference_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                <th onClick={() => this.handleTableSort('customer_name')}>Customer Name {sortedColumn === 'customer_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                <th onClick={() => this.handleTableSort('customer_activity')}>Customer Activity {sortedColumn === 'customer_activity' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                <th onClick={() => this.handleTableSort('customer_email')}>Email {sortedColumn === 'customer_email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                <th onClick={() => this.handleTableSort('number_of_product')}>No of Product {sortedColumn === 'number_of_product' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                {/* <th onClick={() => this.handleTableSort('phone_no')}>Phone {sortedColumn === 'phone_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                                <th onClick={() => this.handleTableSort('date')}>Date {sortedColumn === 'date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                <th onClick={() => this.handleTableSort('revenue')}>Revenue Amount {sortedColumn === 'revenue' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentData.map((data, i) => (
                                <tr key={i}>
                                  <td>{data.customer_reference_no}</td>
                                  <td>{data.customer_name}</td>
                                  <td>{data.customer_activity}</td>
                                  <td>{data.customer_email}</td>
                                  <td>{data.number_of_product}</td>
                                  {/* <td>{data.phone_no}</td> */}
                                  <td>{formatDateAndTime(data.date)}</td>
                                  <td>{data.revenue}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  customerDashboard: reportOperations.customerDashboard
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDashboard)