import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const listReducer = createReducer([])({
    [types.FETCH_CATEGORY_LIST_COMPLETED]: (state, action) => {
        return action.payload.payload
    },
    [types.INSERT_CATEGORY_COMPLETED]: (state, action) => {
        const event = action.payload.payload;
        state.push(event)
        return state.slice()
    },
    [types.UPDATE_CATEGORY_COMPLETED]: (state, action) => {
        const category = action.payload.payload
        return state.map(e => e.id === category.id ? category : e)
    }
});

export default combineReducers({
    list: listReducer
});