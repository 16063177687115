import React, { Fragment, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H6, Image } from "../../../AbstractElements";
import { Password, SignIn, Username } from "../../../Constant";
import { connect } from "react-redux";
// import { get } from "lodash";
import axios from 'axios';
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { login, initializeSession, authenticated/*, fetchListMenusForRole*/ } from "../../../state/ducks/auth/operations";
import logoWhite from '../../../assets/images/logo/logo.png';
import * as API_URL from "../../../env";

const Signin = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  // const [user_name, setUsername] = useState('');
  // const [user_pass, setPassword] = useState('');

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   if (name === 'user_name') {
  //     setUsername(value);
  //   } else if (name === 'user_pass') {
  //     setPassword(value);
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(API_URL.BACKEND_SERVICE_API_URL+'/admin/login', {
        name: username,
        password: password,
      });
      const res = response.data.payload.data;
      props.initializeSession({ user: res.admin, token: res.token });
      localStorage.setItem('user', JSON.stringify(res.admin));
      localStorage.setItem('token', res.token);
      // Store Menus
      // const menuResponse = await props.fetchListMenusForRole({ role_id: user.role_id });
      // const menus = get(menuResponse, 'payload.menus', []);
      // localStorage.setItem('menus', JSON.stringify(menus));
      // props.authenticated();

      toast.success('Login  Successfully', { autoClose: 10000 });
      history(`${process.env.PUBLIC_URL}/dashboard/`);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className='login-card'>
        <div>
          <div className='login-main'>
            <div>
              <Link className="logo" to="/login/">
                <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
              </Link>
            </div>
            <Form className="theme-form" onSubmit={handleLogin}>
              <H6>Log in your account</H6>
              <FormGroup>
                <Label className="col-form-label">{Username}</Label>
                <Input type="text" name="username" placeholder="Enter your Username" value={username}
                  onChange={(e) => setUsername(e.target.value)} />
              </FormGroup>
              <FormGroup className="position-relative">
                <Label className="col-form-label">{Password}</Label>
                <div className="position-relative">
                  <Input type={togglePassword ? "text" : "password"} name="password" placeholder="Enter your Password" value={password}
                    onChange={(e) => setPassword(e.target.value)} />
                  <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? "" : "show"}></span>
                  </div>
                </div>
              </FormGroup>
              <div className="position-relative form-group">
                <div className="checkbox">
                  <Input id="checkbox1" type="checkbox" />
                  <Label for="checkbox1">Remember me</Label>
                </div>
                <Btn attrBtn={{ color: "primary", className: "d-block w-100" }} type="submit">{SignIn}</Btn>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = () => {
  return {}
};

const mapDispatchToProps = {
  login,
  initializeSession,
  authenticated,
  // fetchListMenusForRole
};
export default connect(mapStateToProps, mapDispatchToProps)(Signin);