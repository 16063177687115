// Dashboard
import Dashboard from "../Pages/Dashboard/Dashboard";

// Customer
import Customer from "../Pages/Customer/Customer";
import CustomerDetails from "../Pages/Customer/CustomerDetails";
import CustomerReceipt from "../Pages/Customer/CustomerReceipt";

// Order
import Order from "../Pages/Order/Order";
import OrderDetails from "../Pages/Order/OrderDetails";
import OrderReceipt from "../Pages/Order/OrderReceipt";

// Product
import Product from "../Pages/Product/Product";

// Category
import Category from "../Pages/Category/CategoryList";
import CategoryAdd from "../Pages/Category/CategoryAdd";
import CategoryDetails from "../Pages/Category/CategoryDetails";
import CategoryEdit from "../Pages/Category/CategoryEdit";

// Question
import Question from "../Pages/Question/QuestionList";
import QuestionAdd from "../Pages/Question/QuestionAdd";
import QuestionEdit from "../Pages/Question/QuestionEdit";

// Reporting
import Reporting from "../Pages/Reporting/Reporting";
import SalesDashboard from "../Pages/Reporting/SalesDashboard";
import CustomerDashboard from "../Pages/Reporting/CustomerDashboard";
import QuestionAnalytics from "../Pages/Reporting/QuestionAnalytics";

// Additional Considerations
import AdditionalConsiderations from "../Pages/AdditionalConsiderations/AdditionalConsiderations";
import ResetPassword from "../Pages/AdditionalConsiderations/ResetPassword";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard /> },
  { path: `${process.env.PUBLIC_URL}/customers-management`, Component: <Customer /> },
  { path: `${process.env.PUBLIC_URL}/customers-management/customer-details`, Component: <CustomerDetails /> },
  { path: `${process.env.PUBLIC_URL}/customers-management/customer-details/customer-receipt`, Component: <CustomerReceipt /> },
  { path: `${process.env.PUBLIC_URL}/order-management`, Component: <Order /> },
  { path: `${process.env.PUBLIC_URL}/order-management/order-details`, Component: <OrderDetails /> },
  { path: `${process.env.PUBLIC_URL}/order-management/order-details/order-receipt`, Component: <OrderReceipt /> },
  { path: `${process.env.PUBLIC_URL}/product-listing`, Component: <Product /> },
  { path: `${process.env.PUBLIC_URL}/question-category`, Component: <Category /> },
  { path: `${process.env.PUBLIC_URL}/question-category/category-add`, Component: <CategoryAdd /> },
  { path: `${process.env.PUBLIC_URL}/question-category/category-details`, Component: <CategoryDetails /> },
  { path: `${process.env.PUBLIC_URL}/question-category/category-edit`, Component: <CategoryEdit /> },
  { path: `${process.env.PUBLIC_URL}/question-category/question`, Component: <Question /> },
  { path: `${process.env.PUBLIC_URL}/question-category/question-add`, Component: <QuestionAdd /> },
  { path: `${process.env.PUBLIC_URL}/question-category/question-edit`, Component: <QuestionEdit /> },
  { path: `${process.env.PUBLIC_URL}/reporting-analytics`, Component: <Reporting /> },
  { path: `${process.env.PUBLIC_URL}/sales-report`, Component: <SalesDashboard /> },
  { path: `${process.env.PUBLIC_URL}/reporting-analytics/customer-report`, Component: <CustomerDashboard /> },
  { path: `${process.env.PUBLIC_URL}/reporting-analytics/question-analytics`, Component: <QuestionAnalytics /> },
  { path: `${process.env.PUBLIC_URL}/additional-considerations`, Component: <AdditionalConsiderations /> },
  { path: `${process.env.PUBLIC_URL}/additional-considerations/reset-password`, Component: <ResetPassword /> },
];