import * as types from "./types";
import * as API_URL from "../../../env";

export const Dashboard = (payload) => ({
    type: types.DASHBOARD,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/dashboard/dashboard-status",
        method: "POST",
        body: payload
    },
});
